<template>
  <div class="form">
    <div class="wrapper illustration illustration_point">
      <Backlink title="questionnaire" service="credit" backlink="/credit/amount-credits" />
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "about_credits" | localize }}</p>
          <form @submit.prevent="submitHandler">
            <div v-for="(acc, k) in accounts" :key="k">
              <div class="promo__container" v-if="amount > 1">
                <p class="promo promo_blocks2">{{ "credit" | localize }} {{ k + 1 }}</p>
              </div>
              <div :class="'scroll_to_' + k"></div>

              <div class="form__group">
                <v-autocomplete
                  v-model.lazy="acc.bank"
                  :items="banks"
                  @blur="check(k, 'bank')"
                  :class="{ invalid: checks[k].bank && !acc.bank }"
                  item-text="name"
                  item-value="value"
                  cache-items
                  :label="'s4p11_label_4' | localize"
                  :placeholder="'s4p11_placeholder_2' | localize"
                  append-icon="icon-arrow-down"
                  outlined
                ></v-autocomplete>

                <v-text-field
                  v-model.lazy="acc.price"
                  @blur="check(k, 'price')"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @keypress="onKeyPressHandler"
                  @keyup="onKeyUpArrayHandler(acc.price, k, 'price')"
                  :class="{ invalid: checks[k].price && !acc.price }"
                  :label="'c_s4p11_label_1' | localize"
                  :placeholder="'c_s1p1_placeholder' | localize"
                  append-icon="icon-₪"
                  outlined
                ></v-text-field>
              </div>

              <div class="form__group">
                <v-menu
                  v-model="menus[k].menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="acc.date_formatted"
                      class="v-input-calendar input_number"
                      v-mask.hide-on-empty="dateMask"
                      @focus="acc.date = parseDate(acc.date_formatted)"
                      @blur="acc.date = parseDate(acc.date_formatted)"
                      :class="{ invalid: (checks[k].date && !acc.date) || (acc.date && !isValidDate(acc.date)) }"
                      :hint="( checks[k].date && !acc.date ? 'form_reqiered' : (acc.date && !isValidDate(acc.date) ? 'form_wrong_date' : 'empty')) | localize"
                      :placeholder="'s1p2_date' | localize"
                      :label="'c_s4p11_label_2' | localize"
                      outlined
                      persistent-hint
                      append-icon="icon-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="acc.date"
                    no-title
                    full-width
                    :max="dateTo"
                    @input="menus[k].menu = false; acc.date_formatted = formatDate(acc.date)"
                  ></v-date-picker>
                </v-menu>

                <v-text-field
                  v-model.lazy="acc.payment"
                  @blur="check(k, 'payment')"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @keypress="onKeyPressHandler"
                  @keyup="onKeyUpArrayHandler(acc.payment, k, 'payment')"
                  :class="{ invalid: checks[k].payment && !acc.payment }"
                  :label="'s4p18_label_1' | localize"
                  :placeholder="'s4p9_placeholder_1' | localize"
                  append-icon="icon-₪"
                  outlined
                ></v-text-field>
              </div>

              <div class="form__group">
                <v-text-field
                  v-model.lazy="acc.months"
                  @blur="check(k, 'months')"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @keypress="onKeyPressHandler"
                  @keyup="onKeyUpArrayHandler(acc.months, k, 'months')"
                  :class="{ invalid: checks[k].months && !acc.months }"
                  :label="'s4p11_placeholder_1' | localize"
                  :placeholder="'s4p11_label_3' | localize"
                  outlined
                ></v-text-field>
              </div>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import VueCookies from 'vue-cookies'
import { clientMixin } from '@/mixins/clientMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { formMixin } from '@/mixins/formMixin.js'

const credit = VueCookies.get('credit') || {}

export default {
  name: 'About_Credits',
  props: { amount: { type: Number, default: 1 } },
  components: { Backlink },
  mixins: [clientMixin, commonMixin, numberMixin, formMixin],
  data: () => ({
    dateTo: new Date().toISOString().substr(0, 10),
    dateMask: [/\d/, /\d/, ' / ', /\d/, /\d/, ' / ', /\d/, /\d/, /\d/, /\d/],
    accounts: [],
    menus: [],
    error: null
  }),
  created () {
    // Массив информации о кредитах
    const accounts = credit.credits || []
    this.checks = []

    for (let i = 0; i < this.amount; i++) {
      this.menus.push({ menu: null })
      this.checks.push({ bank: null, price: null, date: null, date_formatted: null, payment: null, months: null })
      this.accounts.push({
        bank: accounts[i] ? accounts[i].bank || null : null,
        price: accounts[i] ? accounts[i].price || null : null,
        date: accounts[i] ? accounts[i].date || null : null,
        date_formatted: accounts[i] ? this.formatDate(accounts[i].date || new Date().toISOString().substr(0, 10)) : null,
        payment: accounts[i] ? accounts[i].payment || null : null,
        months: accounts[i] ? accounts[i].months || null : null
      })
    }

    this.success = !this.checkError()
  },
  computed: { loaded_banks () { return this.$store.state.banks} },
  watch: { loaded_banks () { this.setBanks() } },
  mounted () {
    this.accounts = this.changePrices(this.accounts, { price: 0 })
  },
  methods: {
    checkForm () { return },
    submitHandler () {
      const errorKey = this.checkError(true)
      if (errorKey !== false) {
        this.$scrollTo('.scroll_to_' + errorKey, 300, { offset: -150 })
        this.success = false
        return
      }

      this.success = true
      credit.credits = this.changePrices(this.accounts, { price: 0, payment: 0 }, true)
      this.$cookies.set('credit', credit)
      this.saveClientData('client_credits', '/credit/reg-payment', 'credit')
    }
  }
}
</script>
